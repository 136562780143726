<template>
  <div>
    <div class="search-bar">
      <a-form-model
        ref="searchRef"
        :model="searchForm"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-row>
          <a-col :span="5">
            <a-form-model-item prop="outlets_city_adcode" label="城市市场" name="outlets_city_adcode">
              <a-select v-model="searchForm.outlets_city_adcode" style="width: 100%" @change="search">
                <a-select-option v-if="!isCityManage" value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in manageCityList"
                  :key="index"
                  :value="item.adcode"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="5">
            <a-form-model-item prop="adcode" label="所在城市" name="adcode">
              <a-select v-model="searchForm.adcode" style="width: 100%">
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :value="item.adcode"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-model-item prop="name" label="网点名称" name="name">
              <a-input allowClear v-model="searchForm.name" placeholder="请输入网点名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="网点类型"  prop="type">
              <a-select v-model="searchForm.type" style="width: 90%">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">门店</a-select-option>
                <a-select-option value="2">配送站</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item label="状态"  prop="status">
              <a-select v-model="searchForm.status" style="width: 90%">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">关店</a-select-option>
                <a-select-option value="2">未发布</a-select-option>
                <a-select-option value="3">营业</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <div style="position: relative;top: 4px;">
              <a-button @click="search" type="primary" html-type="submit">搜索</a-button>
              <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table
      ref="tableRef"
      :columnsData="columns"
      rowKey="outlets_id"
      :tableData="tableData"
      :total="total"
      :getListFunc="getTableData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">门店/配送站列表</h4>
          <a-button v-if="!isCityManage" type="primary" @click="handlerAdd" html-type="submit">新增</a-button>
        </div>
      </template>

      <template #principal="{ record }">
        {{record.principal}} {{record.principal_phone}}
      </template>

      <template #status="{ text }">
        <a-tag class="mr-0"
          :color="text == 1 ? 'red' : text == 3 ? 'green' : 'pink'"
        >{{ formatBusinessStatus(text) }}</a-tag>
      </template>

      <template #operation="{ record }">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerOpenEdit(record)">编辑</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerHuifu(record)" v-if="record.status == 1">恢复</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerFabu(record)" v-else-if="record.status == 2">发布</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerGuandian(record)" v-else>关店</a-button>
      </template>
      
    </base-table>
  </div>
</template>

<script>
import BaseTable from "@/components/base-table"
import {
  getCityList,
  getFinanceCityList,
  getOutletsList,
  updateStatus,
} from "@/api/outlets"
import { columns, formatBusinessStatus } from "./columns"

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      total: 0,
      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      columns: columns,
      tableData: [],
      cityList: [],
      manageCityList: [],
      searchForm: {
        outlets_city_adcode: "",
        adcode: "",
        name: "",
        type: "0",
        status: "3",
        page: 1,
        page_count: 20,
      },

      isCityManage: false,
    }
  },
  async mounted() {
    // this.getCityListData()
    await this.getFinanceCityListData()
    this.getTableData()
  },
  methods: {
    formatBusinessStatus: formatBusinessStatus,
    // 对外的城市列表
    async getCityListData() {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },
    // 内部管理的城市列表
    async getFinanceCityListData () {
      let list
      try{
        list = JSON.parse(localStorage.getItem('cityList'))
      }catch(e){
        console.log(e)
      }
      // 如果是城市市场账号
      if(list && list.length) {
        this.isCityManage = true
        this.manageCityList = list
        this.searchForm.outlets_city_adcode = list[0].adcode
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      } 
    },

    async search() {
      this.searchForm.page = 1
      this.getTableData()
    },
    async getTableData() {
      const { data, code } = await getOutletsList(this.searchForm)
      if (code == 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    handlerAdd() {
      this.$router.push({ path: "/outlets-edit" })
    },
    // handlerEdit(item) {
    //   this.$router.push({
    //     path: "/outlets-edit",
    //     query: {
    //       outletsID: item.outlets_id,
    //     },
    //   })
    // },
    handlerOpenEdit(item) {
      const newPage = this.$router.resolve({
        name: "outlets-edit",
        query: { outletsID: item.outlets_id },
      });
      window.open(newPage.href, "_blank");
    },
    handlerHuifu(item) {
      this._updateStatus(item, 3, "恢复后该配送站将恢复接单，确认恢复吗?")
    },
    handlerGuandian(row) {
      this._updateStatus(row, 1, "关店后该配送站将无法接单，确认关店吗?")
    },
    handlerFabu(row) {
      this._updateStatus(row, 3, "是否确认发布门店")
    },
    _updateStatus(row, status, info) {
      let _this = this
      this.$confirm({
        // title: "温馨提示",
        content: info,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          updateStatus({ outlets_id: row.outlets_id, status: status }).then(
            (res) => {
              if (res.code === 0) {
                _this.$message.success("操作成功！")
                _this.getTableData()
              }
            }
          )
        },
      })
    },
    
    resetForm() {
      this.searchForm.page = 1
      this.$refs.searchRef.resetFields()
      this.getTableData()
    },
  },
  watch: {
    // searchForm: {
    //   deep: true,
    //   handler() {
    //     this.getTableData()
    //   },
    // },
  },
}
</script>
